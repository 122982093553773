var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        {
          staticClass: "m-0",
          on: { input: _vm.handleTabChange },
          model: {
            value: _vm.tabIndex,
            callback: function($$v) {
              _vm.tabIndex = $$v
            },
            expression: "tabIndex"
          }
        },
        _vm._l(_vm.scopeTypes, function(type) {
          return _c(
            "b-tab",
            {
              key: type,
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("span", { staticClass: "font-semibold" }, [
                          _vm._v(_vm._s(_vm._f("capitalize")(type)))
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            },
            [
              _c("div", { staticClass: "flex flex-row justify-end mb-8" }, [
                _c(
                  "button",
                  {
                    staticClass: "sk-btn sk-btn-primary rounded-lg",
                    on: { click: _vm.handleCreate }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("apps.campaigns.form.createButton")) +
                        " "
                    )
                  ]
                )
              ]),
              _c("DataTable", {
                attrs: {
                  config: _vm.datatableConfig,
                  elements: _vm.roles,
                  stripe: true,
                  draggable: false,
                  sst: true,
                  pagination: _vm.pagination
                },
                on: { filter: _vm.handleFilter, action: _vm.handleAction }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }