<template>
    <div>
        <b-tabs class="m-0" v-model="tabIndex" @input="handleTabChange">
            <b-tab v-for="type in scopeTypes" :key="type">
                <template #title>
                    <span class="font-semibold">{{ type | capitalize }}</span>
                </template>

                <div class="flex flex-row justify-end mb-8">
                    <button class="sk-btn sk-btn-primary rounded-lg" @click="handleCreate">
                        {{ $t('apps.campaigns.form.createButton') }}
                    </button>
                </div>
                <DataTable
                    :config="datatableConfig"
                    :elements="roles"
                    :stripe="true"
                    :draggable="false"
                    :sst="true"
                    :pagination="pagination"
                    @filter="handleFilter"
                    @action="handleAction"
                />
            </b-tab>
        </b-tabs>
  </div>
</template>

<script>

import DataTable from '@/modules/Shared/Components/table/SKDataTable.vue'
import loader from "@/modules/Shared/Mixins/loader";
import AuthorizationService from "@/modules/Admin/Services/AuthorizationService";
import PaginationService from "@/modules/Shared/Services/PaginationService";

export default {
  name: "AppFeaturesPage",
  components: {DataTable},
  mixins: [loader],
  data() {
    return {
      tabIndex: 0,
      scopeTypes: ['application', 'resort', 'admin'],
      roles: [],
      datatableConfig: [
          { 'field' : 'name', head : this.$t('shops.name'), 'searchable' : true, 'sortable' : false},
          { 'field' : 'features', head : this.$t('admin.authorization.features'), 'searchable' : true, 'sortable' : false},
          { 'field' : 'actions', 'type': 'actions', head : this.$t("geofences.geofences-messages.actions"), 'searchable' : false, 'sortable' : false }
      ],
      pagination: {},
    }
  },
  computed: {
      scopeType() {
          return this.scopeTypes[this.tabIndex]
      },
      baseFilters() {
          return [
              { field: 'scope_type', value: this.scopeType}
          ]
      }
  },
  async created() {
      this.tabIndex = this.$route.params.scopeTypeId
          ? this.$route.params.scopeTypeId - 1
          : 0
      await this.getRoles({
          filters: [...this.baseFilters],
          page: 1
      })
  },
  methods: {
      async handleTabChange() {
          await this.getRoles({
              filters: [...this.baseFilters],
              page: 1
          })
      },
      async getRoles(params) {
          await this.load(async () => {
              const roles = await AuthorizationService.getRoles(PaginationService.parseParameters(params))
              this.roles =  this.parseRoles(roles.data)
              this.pagination = {
                  per_page: roles.per_page,
                  total: roles.total
              }
          })
      },
      async handleFilter(params) {
          const filters = [
              ...this.baseFilters,
              ...params.filters.map(filter => {
                  return {
                      field: filter.field === 'features' ? 'feature_name' : filter.field,
                      value: filter.value
                  }
              })
          ]
          await this.getRoles(
              {
                  filters: filters,
                  page: params.page
              }
          )
      },
      handleCreate() {
          this.$router.push({
              name: 'admin-role-create',
              params: {
                  scopeType: this.scopeType
              }
          });
      },
      async handleAction(type, uuid){
          switch(type) {
              case 'edit':
                  await this.$router.push({
                      name: 'admin-role-edit',
                      params: {
                          'id': uuid,
                      }
                  });
                  break;
              case 'delete':
                  await this.load(async () => {
                      await AuthorizationService.deleteRole(uuid)
                      await this.getRoles({
                          filters: [...this.baseFilters],
                          page: 1
                      })
                  })
                  break;
          }
      },
      parseRoles(roles) {
          return roles.map(role => ({
              uuid: role.id,
              ...role,
              features: role.enabled_features.map(feature => feature.name)
                  .sort()
                  .join(', '),
              actions: [ 'edit', 'delete' ]
          }))
      }
  }
};
</script>
